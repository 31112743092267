import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const messages = {
  de: {
    labels: {
      'c_head_text': 'Hier können Sie ein Geheimnis erstellen oder ein Passwort generieren. Bei leerem Textfeld wird automatisch ein zufälliges Passwort generiert.',
      'c_head_secret': 'Geheimnis Erstellen',
      'c_secret_textarea': 'Hier Geheimnis einfügen',
      'c_input_passphrase': 'Passwort für Geheimnis',

      'c_select_link_active_text': 'Wie lange soll der Link aktiv sein?',
      'c_select_active_unlimited': 'Bis er aufgerufen wird',
      'c_select_active_hour': '1 Stunde',
      'c_select_active_day': '1 Tag',
      'c_select_active_week': '1 Woche',
      'c_select_active_month': '1 Monat',

      'c_copy_head_text': 'Kopieren Sie ihren geheimen Link oder zerstören Sie ihn, bevor das Geheimnis gelesen werden kann.',
      'c_copy_head_link': 'Geheimer Link',
      'c_copy_head_password': 'Geheimes generiertes Passwort:',
      'c_copy_head_link_send': 'Link verschicken:',

      'c_login_head_text': 'Login zum Geheimnis.',
      'c_login_head': 'Geheimnis',

      'c_show_secret_head_text': 'Dieses Geheimnis ist für Sie.',
      'c_show_secret_head': 'Geheimnis',

      'c_delete_title': 'Link zerstören',

      'c_unknown_head': 'Unbekanntes Geheimnis',

      'c_secret_deleted': 'Geheimnis gelöscht'
    },
    hints: {
      'c_delete_secret': 'Wenn Sie das Geheimnis zerstören, wird es gelöscht bevor es gelesen werden kann!',
      'c_secret_password': 'Kopieren Sie ihr geheimes Passwort, denn es wird nur einmal angezeigt.',
      'c_attention': 'Achtung: ',
      'c_automatic_deletion': 'Ist kein Ablaufdatum festgelegt, wird das Geheimnis nach spätestens einem Jahr automatisch gelöscht!'
    },
    btn: {
      'c_delete': 'Link zerstören >',
      'c_copy': 'Link kopieren >',
      'c_copy_passsword': 'Passwort kopieren >',
      'c_create': 'Link erzeugen >',
      'c_create_passphrase': 'Link für Passwort erzeugen >',
      'c_login_password': 'Geheimnis zeigen >',
      'c_unknown_secret': 'Generiere dein eigenes Geheimnis >',
      'c_destroy_secrect_confirm': 'Zerstören',
      'c_abort': 'Abbrechen',
      'c_secret_deleted': 'Neues Geheimnis erstellen >',
      'c_generate_password': 'Passwort generieren'
    },
    message: {
      'c_unknown_secret': 'Dieses Geheimnis existiert nicht oder es wurde schon angezeigt.',
      'c_404_delete': 'Dieses Geheimnis existiert nicht oder es wurde bereits gelöscht bzw. angezeigt.',
      'c_burn_link_message': 'Wenn Sie diesen Link zerstören, kann er nicht wiederhergestellt werden!',
      'c_wrong_password': 'Das Passwort ist nicht gültig!',
      'c_secret_deleted': 'Ihr Geheimnis wurde erfolgreich gelöscht und kann nicht mehr angesehen oder wiederhergestellt werden.',
      'c_copied_to_clipboard': 'In die Zwischenablage kopiert!'
    },
    header: {
      secret: 'Secret',
      api: 'API Dokumentation'
    },
    footer: {
      impress: {
        text: 'Impressum',
        link: 'https://anexia.com/de/unternehmen/ueber-anexia/impressum'
      },
      contact: {
        text: 'Kontakt',
        link: 'https://anexia.com/de/unternehmen/kontakt'
      },
      agb: {
        text: 'AGB',
        link: 'https://anexia.com/de/unternehmen/ueber-anexia/agb'
      },
      dataProtection: {
        text: 'Datenschutz',
        link: 'https://anexia.com/de/unternehmen/ueber-anexia/datenschutz'
      }
    },
    error: {
      swagger: {
        not_supported: 'Unser Dokumnetationstool wird von Ihrem Browser nicht unterstützt. Wechseln Sie Ihren Browser, um die Dokumentation anzuzeigen.'
      },
      ie_support: {
        title: 'Browser Unterstützung',
        text: [
          'Leider wird unsere Anwendung ',
          'von Ihrem Browser nicht unterstützt',
          '. Sie können diesen Fehler beheben, indem Sie einen neueren Browser verwenden.'
        ],
        description: {
          title: 'Warum unterstützen wir keine alten Browser?',
          text: [
            'Alte Browser können meistens nicht genügend Sicherheit gewährleisten. Da unsere Anwendung dazu genutzt wird Passwörter und vertrauliche Informationen auszutauschen, legen wir großen Wert auf Sicherheit.',
            'Unsere Anwendung verwendet modernste Technologien, um Ihnen ein optimales Nutzererlebnis und Datensicherheit zur Verfügung zu stellen. Alte Browser sind mit diesen Technologien nicht kompatibel.'
          ]
        }
      }
    }
  },
  en: {
    labels: {
      'c_head_text': 'Here you can create a secret or generate a password. If the text field is empty, a random password is generated automatically.',
      'c_head_secret': 'Create secret',
      'c_secret_textarea': 'Paste your secret here',
      'c_input_passphrase': 'Password for your secret',

      'c_select_link_active_text': 'How long should the link be active?',
      'c_select_active_unlimited': 'Until it\'s viewed',
      'c_select_active_hour': '1 Hour',
      'c_select_active_day': '1 Day',
      'c_select_active_week': '1 Week',
      'c_select_active_month': '1 Month',

      'c_copy_head_text': 'Copy your secret link or destroy it before the secret can be viewed.',
      'c_copy_head_link': 'Secret link',
      'c_copy_head_password': 'Secret generated password:',
      'c_copy_head_link_send': 'Send Link:',

      'c_login_head_text': 'Login to the secret.',
      'c_login_head': 'Secret',

      'c_show_secret_head_text': 'This secret is for you.',
      'c_show_secret_head': 'Secret',

      'c_delete_title': 'Destroy Link to',

      'c_unknown_head': 'Unknown secret',

      'c_secret_deleted': 'Secret deleted'
    },
    hints: {
      'c_delete_secret': 'If you destroy the secret, it will be deleted before it can be read!',
      'c_secret_password': 'Copy your secret password because it will only be displayed once.',
      'c_attention': 'Note: ',
      'c_automatic_deletion': 'If no expire date is provided, the secret is automatically deleted after one year!'
    },
    btn: {
      'c_delete': 'Destroy Link >',
      'c_copy': 'Copy link >',
      'c_copy_passsword': 'Copy password >',
      'c_create': 'Create link >',
      'c_create_passphrase': 'Create link for secret password >',
      'c_login_password': 'Show secret >',
      'c_unknown_secret': 'Create your own secret >',
      'c_destroy_secrect_confirm': 'Destroy',
      'c_abort': 'Abort',
      'c_secret_deleted': 'Create a new secret >',
      'c_generate_password': 'Generate password'
    },
    message: {
      'c_unknown_secret': 'This secret either never existed or has already been shown.',
      'c_404_delete': 'This secret either never existed or has already been deleted or shown.',
      'c_burn_link_message': 'Destroying a secret is permanent and can not be undone!',
      'c_wrong_password': 'Wrong password!',
      'c_secret_deleted': 'Your secret has successfully been deleted and can no longer be viewed or restored.',
      'c_copied_to_clipboard': 'Copied to clipboard!'
    },
    header: {
      secret: 'Secret',
      api: 'API Docs'
    },
    footer: {
      impress: {
        text: 'Imprint',
        link: 'https://anexia.com/en/company/about-anexia/imprint'
      },
      contact: {
        text: 'Contact',
        link: 'https://anexia.com/en/company/contact'
      },
      agb: {
        text: 'Terms of Use',
        link: 'https://anexia.com/en/company/about-anexia/terms-of-use'
      },
      dataProtection: {
        text: 'Privacy Policy',
        link: 'https://anexia.com/en/company/about-anexia/privacy-policy'
      }
    },
    error: {
      swagger: {
        not_supported: 'Our documentation tool is not supported by your browser. Change your browser to view the documentation.'
      },
      ie_support: {
        title: 'Browser support',
        text: [
          'Unfortunately, our application',
          'doesn\'t support the browser you are currently using',
          '. You can fix this error by using a newer browser.'
        ],
        description: {
          title: 'Why we don\'t support old browser',
          text: [
            'Old browsers often don\'t provide enough security. Since our application can be used to create passwords and exchange confidential information, we place great emphasis on security.',
            'Our application uses the latest technology to provide our customers a great experience and protect their data. Old browser may not be compatible with this technology.'
          ]
        }
      }
    }
  }
}

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: window.navigator.language.slice(0, 2) === 'de' ? 'de' : 'en', // set locale
  messages // set locale messages
})

export default i18n
