import { Camelizer } from './Camelizer'

export class SerializationHelper {
  public static toInstance (obj: any, json: any) {
    let jsonObj: any
    if (typeof (json) === 'string') {
      jsonObj = JSON.parse(json)
    } else {
      jsonObj = json
    }

    // Convert all keys to camel case, e.g. form_type => formType
    Object.keys(jsonObj).forEach((k) => {
      const camelized = Camelizer.camelize(k)
      if (camelized !== k) {
        jsonObj[camelized] = jsonObj[k]
        delete jsonObj[k]
      }
    })

    if (typeof obj.fromJSON === 'function') {
      obj.fromJSON(jsonObj)
    } else {
      for (const propName in jsonObj) {
        if (obj.hasOwnProperty(propName)) {
          obj[propName] = jsonObj[propName]
        }
      }
    }

    return obj
  }

  public static toInstanceArray (Obj: any, json: any) {
    const instances: Array<any> = []
    json.forEach((jsonObject: any) => {
      instances.push(this.toInstance(new Obj(), jsonObject))
    })
    return instances
  }
}
