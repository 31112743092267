import Vue from 'vue'
import VueRouter from 'vue-router'
import CreateSecret from '../views/CreateSecret.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'createSecret',
    component: CreateSecret
  },
  {
    path: '/',
    name: 'secretDeleted',
    component: () => import('@/views/SecretDeleted.vue')
  },
  {
    path: '/',
    name: 'secretCreated',
    component: () => import('@/views/SecretCreated.vue'),
    props: true
  },
  {
    path: '/secret/:url',
    name: 'loadSecret',
    component: () => import('@/views/LoadSecret.vue'),
    props: true
  },
  {
    path: '/secret/:url',
    name: 'showSecret',
    component: () => import('@/views/ShowSecret.vue'),
    props: true
  },
  {
    path: '/docs',
    name: 'Api',
    component: () => import('@/views/Api.vue')
  },
  {
    path: '/ie-support',
    name: 'IESupport',
    component: () => import('@/views/IESupport.vue')
  },
  {
    path: '/*',
    name: 'Error',
    component: () => import('@/views/Error404.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
