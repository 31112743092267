import Vue from 'vue'
import App from './App.vue'
import i18n from './i18n'
import router from './router'
import Vuex from 'vuex'
import store from '@/store'
import AnxUIPlugin from '@anexia/vue-ui-components'

import '@anexia/vue-ui-components/lib/anx.css'
import '@/assets/scss/fonts.scss'

Vue.use(AnxUIPlugin, {
  variables: {
    '--font-family': "'Meta', Arial, Helvetica, sans-serif"
  }
})
Vue.config.productionTip = false

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
