import { BadRequestException } from '@/exceptions/BadRequestException'
import { ForbiddenException } from '@/exceptions/ForbiddenException'
import { NotFoundException } from '@/exceptions/NotFoundException'
import { SerializationHelper } from '@/utils/SerializationHelper'
import { Secret } from './Secret'

export abstract class AbstractModel {
  private static readonly apiBase = '/api'

  public static async get<T extends AbstractModel> (this: new() => T, id: string | number): Promise<T> {
    const res = await fetch(AbstractModel.apiBase + '/' + this.name.toLowerCase() + '/' + id)
    switch (res.status) {
      case 403:
        throw new ForbiddenException()
      case 404:
        throw new NotFoundException()
    }

    const resData = await res.json()

    return SerializationHelper.toInstance(new this(), resData)
  }

  public static async delete (id: string | number): Promise<any> {
    const res = await fetch(this.apiBase + '/' + this.name.toLowerCase() + '/' + id, {
      method: 'DELETE'
    })
    switch (res.status) {
      case 403:
        throw new ForbiddenException()
      case 404:
        throw new NotFoundException()
    }

    return res.json()
  }

  public async create (): Promise<boolean> {
    const res = await fetch(AbstractModel.apiBase + '/' + this.constructor.name.toLowerCase(), {
      method: 'POST',
      body: JSON.stringify(this),
      headers: {
        'Content-Type': 'application/json'
      }
    })
    switch (res.status) {
      case 403:
        throw new ForbiddenException()
      case 404:
        throw new NotFoundException()
      case 400:
        throw new BadRequestException()
    }

    const resData = await res.json()

    Object.getOwnPropertyNames(this).forEach(propName => {
      if (resData.hasOwnProperty(propName)) {
        (this as any)[propName] = resData[propName]
      }
    })

    if (resData.status === 'success') {
      return true
    }

    return false
  }
}
