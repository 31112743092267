
import { Vue, Component } from 'vue-property-decorator'

import 'whatwg-fetch'

import { Secret } from '../models/Secret'

import ResizeObserver from 'resize-observer-polyfill'

@Component({})
export default class CreateSecret extends Vue {
  options = [
    { value: '', text: this.$i18n.tc('labels.c_select_active_unlimited') },
    { value: '+1 hour', text: this.$i18n.tc('labels.c_select_active_hour') },
    { value: '+1 day', text: this.$i18n.tc('labels.c_select_active_day') },
    { value: '+1 week', text: this.$i18n.tc('labels.c_select_active_week') },
    { value: '+1 month', text: this.$i18n.tc('labels.c_select_active_month') }
  ];

  private secretError = ''
  /** The secret */
  private secret: Secret = new Secret()

  /** Request a random password from the API and set it as secret */
  private async createPassword () {
    const res = await fetch('/api/password', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })

    const resData = await res.json()
    this.secret.secret = resData.password

    // Focus and select the generated password
    const secretTextarea = document.getElementById('secret-textarea') as HTMLInputElement
    if (secretTextarea != null) {
      secretTextarea.focus()
      window.setTimeout(() => {
        secretTextarea.select()
      }, 10)
    }
  }

  /** Update the texts on e.g. langauge change */
  private async beforeUpdate () {
    this.options = [
      { value: '', text: this.$i18n.tc('labels.c_select_active_unlimited') },
      { value: '+1 hour', text: this.$i18n.tc('labels.c_select_active_hour') },
      { value: '+1 day', text: this.$i18n.tc('labels.c_select_active_day') },
      { value: '+1 week', text: this.$i18n.tc('labels.c_select_active_week') },
      { value: '+1 month', text: this.$i18n.tc('labels.c_select_active_month') }
    ]

    /** Update the select manually on update so that the text is also changed on language change */
    const res = this.options.filter(elem => elem.value === this.secret.expire)
    if (res.length > 0) {
      // @ts-ignore (because the function from AnxSelect does not exist in type Vue Element but exists in vue component)
      this.$refs.selectExpireTime.select({ value: this.secret.expire, text: res[0].text })
    }
  }

  /** This method is used when creating a secret */
  private async create (validate = true) {
    const isSecretEmpty = this.secret.secret === null || this.secret.secret === ''
    try {
      const secret = await this.secret.create()
    } catch (ex) {
      this.secretError = 'Secret exceeded maximum string length'
      return
    }

    this.$router.push({
      name: 'secretCreated',
      params: {
        url: this.secret.url as string,
        showSecret: isSecretEmpty ? '1' : ''
      }
    })
    /** Delete the content of the secret after creating it, otherwise it would be shown on the secret created screen as password */
    this.$store.dispatch('setSecret', this.secret)
  }

  /** Set a resize listener for the text area */
  private mounted () {
    const textarea: HTMLElement = document.getElementById('secret-textarea') as HTMLElement
    const resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        if (entry.target === textarea) {
          /** Calculate the new width for the view and check if it is greater than the minimum width */
          const width = (entry.target as HTMLElement).offsetWidth + 110
          if (width >= 530 && width < window.innerWidth - 100) {
            this.$store.commit('setContentWidth', width)
          }
        }
      }
    })

    /** Observe the textarea */
    resizeObserver.observe(textarea)
  }
}
