import Vue from 'vue'
import Vuex from 'vuex'
import { Secret } from '@/models/Secret'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    secret: new Secret(),
    password: null,
    i18n: {
      locales: ['en', 'de'],
      locale: 'en'
    },
    contentWidth: 530
  },

  mutations: {
    setSecret (state, secret) {
      state.secret = secret
    },
    setPassword (state, password) {
      state.password = password
    },
    setLocale (state, locale) {
      if (state.i18n.locales.includes(locale)) {
        state.i18n.locale = locale
        localStorage.setItem('locale', locale)
      }
    },
    setContentWidth (state, width: number) {
      state.contentWidth = width
    },
    initialiseLocale (state) {
      /** Try to get the language from local storage */
      const locale: string = String(localStorage.getItem('locale'))

      if (state.i18n.locales.includes(locale)) {
        state.i18n.locale = locale
      } else {
        /** If nothing is set in the local storage, try to get the browser language */
        const browserLanguage = (navigator.language).split('-')[0]

        if (state.i18n.locales.includes(browserLanguage)) {
          state.i18n.locale = browserLanguage
        }
      }
    }
  },

  actions: {
    setSecret (context, secret) {
      context.commit('setSecret', secret)
    },
    setPassword (context, password) {
      context.commit('setPassword', password)
    },
    setLocale (context, locale) {
      context.commit('setLocale', locale)
    },
    setContentWidth (context, width) {
      context.commit('setLocale', width)
    }
  }
})
