
import { Vue, Component } from 'vue-property-decorator'
import router from 'vue-router'
import store from 'vuex'
import i18n from 'vue-i18n'

@Component({})
export default class App extends Vue {
  /** The items for the header menu */
  private menuItems: Array<object> = []

  /** Set locale on locale change */
  private setLocale (lang: string) {
    this.$store.commit('setLocale', lang)

    /** Vue has bad reactivity for arrays, so we have to change the whole array on langauge change */
    this.initMenuItems()
  }

  /** This initialises the menu items */
  private initMenuItems () {
    this.menuItems = [
      { menu: this.$t('header.secret'), link: '/', activeRegex: /[^/]{1}\/{1}secret.*/ },
      { menu: this.$t('header.api'), link: '/docs' }
    ]
  }

  /** Initialise the store before create */
  private beforeCreate () {
    this.$store.commit('initialiseLocale')
    this.$i18n.locale = this.$store.state.i18n.locale
  }

  /** Check for IE before mount and redirect if necessary */
  private beforeMount () {
    if (this.isInternetExplorer() && !(this.$route.name === 'IESupport' || window.location.href.indexOf('/ie-support') > 0)) {
      this.$router.push({ name: 'IESupport' })
    } else if (!this.isInternetExplorer() && (this.$route.name === 'IESupport' || window.location.href.indexOf('/ie-support') > 0)) {
      this.$router.push({ name: 'createSecret' })
    }

    this.initMenuItems()
  }

  /** Checks if the current browser is IE */
  public isInternetExplorer () {
    const ua = navigator.userAgent
    /* MSIE used to detect old browsers and Trident used to newer ones */
    return ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1
  }

  /** Returns the current width for the anx-content */
  private get contentWidth () {
    const width = window.innerWidth
    if (this.$route.name === 'Api') {
      if (width > 992) {
        return '840px'
      }
    }

    return this.$store.state.contentWidth + 'px'
  }

  /** Don't show the language switcher on the api docs (openapi doesn't have language support) */
  private get showLanguageSwitcher (): boolean {
    return this.$route.name !== 'Api'
  }
}
